import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Curt Henderson"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Curt Henderson"
        videoUrl="https://vimeo.com/500671426/259828a038"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.curtHendersonImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Curt Henderson
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and producer
            </Text>
            <Box>
              <Link to="https://www.instagram.com/curthenderson_" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/curthendersonguitar/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Curt Henderson is a Nashville-based guitarist, producer, and music
              educator. After completing a degree in Jazz Guitar Performance in
              his hometown of Vancouver, Canada, Curt moved to Nashville, TN and
              has since become an in-demand figure in Music City’s busy scene.
              He has played, recorded, and toured internationally with major
              label artists like EZA (Ellery Bonham), John King,
              American Idol finalist Jonny Brenns, and has his own project with
              his musical partner in crime, Shannon Lauren Callihan. As
              Nashville’s culture demands, Curt is equally at home on an r&b
              session one day and then a country artist’s tour the next day.
            </Text>
            <Text level="2">
              He is also passionate about his work as a featured artist and
              performer with brands like Fender, PRS, Music Man, D’Angelico,
              Supro, Strymon Engineering, and many other notable companies, as
              well as being a panel guest for institutions like Guitar.com.
              Curt’s teenage dream came true when Fender asked him to be their
              featured artist in the release of their 2019 Stratocaster line.
              All in, Curt’s music releases and videos have received millions of
              listens and views across platforms as he continues to grow his
              career as a performer and producer.
            </Text>
            <Text level="2">
              A very significant part of Curt’s musical career has also been
              spent in music education. He is a dedicated educator, and has been
              invited to lead clinics and speak at colleges about the principles
              of effective guitar study, improvisation techniques, and how to
              translate the fundamentals of jazz and music theory to mainstream
              genres like r&b, funk, pop, and country. Curt has been teaching
              for over a decade and takes pride in working with students with
              the goal of mapping out the fretboard in a structured, systematic
              way so they can play more expressively and with intention. Curt is
              really just forever trying to recreate the same “lightbulb
              moments” that he experienced in his own development for each one
              of the students that he mentors today.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Curt Henderson" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    curtHendersonImage: file(
      relativePath: { eq: "curtHenderson-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
